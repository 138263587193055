<template>
    <div>
    <div v-if="isLoading">
        <CanPayLoader/>
    </div>
  <div class="container min-h p-4" v-else>
    <div>
       <div class="rp-available-card mb-3" style="padding:10px 20px!important;">
            <div class="rp-available-title mb-1">Available CanPay Points</div>
            <div class="rp-available-value">
                <svg class=" mb-2" width="40" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35" cy="35" r="35" fill="black"/>
                <path d="M58.0327 44.4525C60.969 38.2154 59.7673 32.2515 59.4272 30.6694C59.0417 28.8825 58.0667 24.66 54.5183 20.8699C52.8282 19.0889 50.821 17.6413 48.6004 16.6018C45.6075 15.2019 43.0567 14.9287 41.6622 14.7921C35.9144 14.223 31.1643 15.9075 28.5114 17.114C29.8031 15.8716 31.2794 14.8387 32.8875 14.0523C34.3086 13.3704 35.8149 12.8842 37.3656 12.6069C39.9046 12.1197 42.5076 12.0697 45.0633 12.4589C46.2423 12.641 51.1626 13.4946 56.0714 17.3188C63.225 22.8844 66.1613 30.6125 64.4947 39.5016C61.9553 53.1026 48.9632 61.0697 35.7897 57.5641C31.9125 56.5284 27.8312 54.0814 26.0173 51.6912C26.108 51.6912 26.2101 51.6685 26.2667 51.7026C26.7202 51.9985 27.1624 52.3172 27.6272 52.6131C34.9962 57.2568 42.5465 57.291 50.0515 53.1367C51.0019 52.6094 51.9047 52.0001 52.7497 51.3156C56.0034 48.6751 57.4885 45.6021 58.0327 44.4525Z" fill="white"/>
                <path d="M65.1182 45.2722C65.0389 45.7502 64.9028 46.4331 64.6874 47.2412C63.1229 53.023 59.5518 56.6765 58.0213 58.2016C52.4209 63.79 45.9022 65.4062 42.9206 66.1119C36.7873 67.5573 31.8331 66.9427 30.0532 66.6582C23.6479 65.6338 19.2492 63.0388 17.9001 62.1852C15.1477 60.4502 12.6808 58.2961 10.5878 55.8001C8.14327 52.8969 6.22821 49.5834 4.93068 46.012C4.60191 45.1014 3.35485 41.5276 3.03741 36.6335C2.74266 31.9671 3.44554 28.4843 3.6156 27.6762C4.17003 25.0362 5.06567 22.4803 6.27977 20.0733C6.91463 18.8441 10.1456 12.8346 17.2879 8.35027C18.9431 7.30316 23.2738 4.84474 29.407 3.91145C31.0282 3.66105 45.0066 1.72618 55.0171 10.5242C59.6539 14.5988 62.386 20.0505 62.8168 20.9269C63.4801 22.2806 64.0557 23.6759 64.54 25.104C64.3813 24.8877 63.2023 23.226 63.1683 23.1805C58.8149 16.7044 53.7587 13.6541 53.7587 13.6541C51.8468 12.5265 49.7911 11.665 47.6481 11.0932C40.8346 9.24941 35.0981 10.8884 33.7377 11.2981C25.7565 13.6996 21.0291 20.0847 20.757 20.4603C15.9388 27.1299 16.0408 34.0954 16.1315 36.0303C16.4376 42.5406 19.2718 47.1387 20.5642 48.9939C20.6663 49.1191 20.8137 49.3126 20.995 49.5403C21.1311 49.7224 21.2671 49.9045 21.4032 50.0866C24.7816 54.5482 29.2596 57.6553 34.2365 59.2032C37.9454 60.3419 41.8646 60.6138 45.6942 59.9984C49.5239 59.3829 53.1626 57.8962 56.3321 55.6522C59.121 53.6718 60.8102 51.6117 61.8532 50.337C63.2363 48.6525 64.2453 46.9908 64.5741 46.3306C64.6194 46.251 65.0956 45.2722 65.1182 45.2722Z" fill="#007EE5"/>
                <path d="M28.7061 45.6C22.7202 45.6 19.7273 41.6278 19.7273 35.5387C19.7273 29.2447 22.879 25.4546 29.0462 25.4546C31.427 25.4546 33.3429 26.0237 34.8167 27.2301C36.1998 28.4707 36.9707 30.1552 37.1634 32.2949H34.76C33.8984 32.2949 33.2749 31.8966 32.9008 31.1112C32.2432 29.7113 30.9508 28.9943 29.0576 28.9943C25.3617 28.9943 23.7179 31.612 23.7179 35.5501C23.7179 39.3743 25.2937 42.0717 28.9329 42.0717C31.427 42.0717 32.8554 40.6945 33.2862 38.532H37.1521C36.8007 43.0392 33.6377 45.6 28.7061 45.6Z" fill="white"/>
                <path d="M49.3373 25.2747H42.5465C41.4015 25.2747 40.4945 26.2762 40.4945 27.4258V45.3063H44.5758V38.17H49.632C53.634 38.17 55.7766 35.6661 55.7766 31.7166C55.7766 27.551 53.5773 25.2747 49.3373 25.2747ZM48.8725 34.5507H44.8932V28.746H49.0765C50.9811 28.746 51.9561 29.7135 51.9561 31.6597C51.9674 33.606 50.9471 34.5848 48.8725 34.5507H48.8725Z" fill="#007EE5"/>
                </svg> 
                <span style="font-size:41px;" class="ml-1">{{pointNumberFormatter(parseFloat(rewardPoints.available_canpay_points.reward_point) + parseFloat(rewardPoints.available_merchant_points))}}</span>
            </div>


        </div>
        <!-- Available Reward Points division Card -->
        <div class="rp-available-card mb-3">
            <div class="row" style="font-size:15px;color:#ffffff;padding:0px 10px;white-space:nowrap;">
                <div class="col-5 text-left" style="padding:0px!important; ">
                    <span>
                        <svg width="25" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="35" cy="35" r="35" fill="black"/>
                        <path d="M58.0327 44.4525C60.969 38.2154 59.7673 32.2515 59.4272 30.6694C59.0417 28.8825 58.0667 24.66 54.5183 20.8699C52.8282 19.0889 50.821 17.6413 48.6004 16.6018C45.6075 15.2019 43.0567 14.9287 41.6622 14.7921C35.9144 14.223 31.1643 15.9075 28.5114 17.114C29.8031 15.8716 31.2794 14.8387 32.8875 14.0523C34.3086 13.3704 35.8149 12.8842 37.3656 12.6069C39.9046 12.1197 42.5076 12.0697 45.0633 12.4589C46.2423 12.641 51.1626 13.4946 56.0714 17.3188C63.225 22.8844 66.1613 30.6125 64.4947 39.5016C61.9553 53.1026 48.9632 61.0697 35.7897 57.5641C31.9125 56.5284 27.8312 54.0814 26.0173 51.6912C26.108 51.6912 26.2101 51.6685 26.2667 51.7026C26.7202 51.9985 27.1624 52.3172 27.6272 52.6131C34.9962 57.2568 42.5465 57.291 50.0515 53.1367C51.0019 52.6094 51.9047 52.0001 52.7497 51.3156C56.0034 48.6751 57.4885 45.6021 58.0327 44.4525Z" fill="white"/>
                        <path d="M65.1182 45.2722C65.0389 45.7502 64.9028 46.4331 64.6874 47.2412C63.1229 53.023 59.5518 56.6765 58.0213 58.2016C52.4209 63.79 45.9022 65.4062 42.9206 66.1119C36.7873 67.5573 31.8331 66.9427 30.0532 66.6582C23.6479 65.6338 19.2492 63.0388 17.9001 62.1852C15.1477 60.4502 12.6808 58.2961 10.5878 55.8001C8.14327 52.8969 6.22821 49.5834 4.93068 46.012C4.60191 45.1014 3.35485 41.5276 3.03741 36.6335C2.74266 31.9671 3.44554 28.4843 3.6156 27.6762C4.17003 25.0362 5.06567 22.4803 6.27977 20.0733C6.91463 18.8441 10.1456 12.8346 17.2879 8.35027C18.9431 7.30316 23.2738 4.84474 29.407 3.91145C31.0282 3.66105 45.0066 1.72618 55.0171 10.5242C59.6539 14.5988 62.386 20.0505 62.8168 20.9269C63.4801 22.2806 64.0557 23.6759 64.54 25.104C64.3813 24.8877 63.2023 23.226 63.1683 23.1805C58.8149 16.7044 53.7587 13.6541 53.7587 13.6541C51.8468 12.5265 49.7911 11.665 47.6481 11.0932C40.8346 9.24941 35.0981 10.8884 33.7377 11.2981C25.7565 13.6996 21.0291 20.0847 20.757 20.4603C15.9388 27.1299 16.0408 34.0954 16.1315 36.0303C16.4376 42.5406 19.2718 47.1387 20.5642 48.9939C20.6663 49.1191 20.8137 49.3126 20.995 49.5403C21.1311 49.7224 21.2671 49.9045 21.4032 50.0866C24.7816 54.5482 29.2596 57.6553 34.2365 59.2032C37.9454 60.3419 41.8646 60.6138 45.6942 59.9984C49.5239 59.3829 53.1626 57.8962 56.3321 55.6522C59.121 53.6718 60.8102 51.6117 61.8532 50.337C63.2363 48.6525 64.2453 46.9908 64.5741 46.3306C64.6194 46.251 65.0956 45.2722 65.1182 45.2722Z" fill="#007EE5"/>
                        <path d="M28.7061 45.6C22.7202 45.6 19.7273 41.6278 19.7273 35.5387C19.7273 29.2447 22.879 25.4546 29.0462 25.4546C31.427 25.4546 33.3429 26.0237 34.8167 27.2301C36.1998 28.4707 36.9707 30.1552 37.1634 32.2949H34.76C33.8984 32.2949 33.2749 31.8966 32.9008 31.1112C32.2432 29.7113 30.9508 28.9943 29.0576 28.9943C25.3617 28.9943 23.7179 31.612 23.7179 35.5501C23.7179 39.3743 25.2937 42.0717 28.9329 42.0717C31.427 42.0717 32.8554 40.6945 33.2862 38.532H37.1521C36.8007 43.0392 33.6377 45.6 28.7061 45.6Z" fill="white"/>
                        <path d="M49.3373 25.2747H42.5465C41.4015 25.2747 40.4945 26.2762 40.4945 27.4258V45.3063H44.5758V38.17H49.632C53.634 38.17 55.7766 35.6661 55.7766 31.7166C55.7766 27.551 53.5773 25.2747 49.3373 25.2747ZM48.8725 34.5507H44.8932V28.746H49.0765C50.9811 28.746 51.9561 29.7135 51.9561 31.6597C51.9674 33.606 50.9471 34.5848 48.8725 34.5507H48.8725Z" fill="#007EE5"/>
                        </svg> <span>CanPay Points:</span>
                    </span>
                </div>
                <div class="col-7 text-right text-bold" style="font-size:15px;color:#179346;padding:0px!important;">
                    {{pointNumberFormatter(parseFloat(rewardPoints.available_canpay_points.reward_point))}}
                </div>
            </div>
            <div class="row mt-2" style="font-size:15px;color:#ffffff;padding:0px 10px;white-space:nowrap;">
                <div class="col-5 text-left" style="padding:0px!important;">
                    <span style="margin-left: 0.5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 60 60" fill="none">
                        <path d="M52.3781 38.6548C55.171 32.7591 54.028 27.1216 53.7045 25.6262C53.3378 23.9371 52.4105 19.9456 49.0353 16.363C47.4277 14.6796 45.5185 13.3112 43.4063 12.3286C40.5595 11.0053 38.1333 10.7471 36.8069 10.618C31.3397 10.08 26.8215 11.6723 24.2982 12.8127C25.5267 11.6384 26.931 10.662 28.4606 9.91866C29.8123 9.2741 31.2451 8.8145 32.72 8.55232C35.135 8.09188 37.611 8.04458 40.0419 8.41246C41.1634 8.58459 45.8434 9.39149 50.5126 13.0064C57.3169 18.2673 60.1098 25.5724 58.5246 33.9748C56.1092 46.8313 43.7514 54.3623 31.2211 51.0487C27.5332 50.0696 23.6512 47.7565 21.9258 45.4972C22.0121 45.4972 22.1092 45.4757 22.1631 45.508C22.5944 45.7877 23.015 46.089 23.4571 46.3687C30.4663 50.7582 37.648 50.7905 44.7866 46.8636C45.6905 46.3652 46.5493 45.7892 47.3531 45.1422C50.4479 42.6462 51.8605 39.7414 52.3781 38.6548Z" fill="white"/>
                        <path d="M59.1177 39.4294C59.0422 39.8812 58.9128 40.5267 58.7079 41.2906C57.2198 46.756 53.823 50.2095 52.3673 51.6511C47.0403 56.9336 40.8398 58.4613 38.0038 59.1283C32.17 60.4947 27.4577 59.9137 25.7647 59.6447C19.6721 58.6765 15.4881 56.2235 14.2049 55.4166C11.5869 53.7766 9.24045 51.7404 7.2496 49.3811C4.92444 46.6367 3.10287 43.5046 1.86869 40.1287C1.55597 39.268 0.369802 35.8898 0.0678669 31.2636C-0.212501 26.8526 0.456069 23.5605 0.617819 22.7966C1.14519 20.3012 1.9971 17.8851 3.15191 15.6099C3.75578 14.448 6.82905 8.76745 13.6226 4.52857C15.1969 3.53878 19.3162 1.21493 25.15 0.33273C26.692 0.0960419 39.9879 -1.73292 49.5097 6.58346C53.9201 10.435 56.5189 15.5884 56.9286 16.4168C57.5595 17.6964 58.107 19.0153 58.5677 20.3652C58.4167 20.1608 57.2953 18.59 57.2629 18.547C53.1221 12.4254 48.3127 9.54207 48.3127 9.54207C46.4942 8.47618 44.5389 7.66184 42.5005 7.12139C36.0197 5.3785 30.5633 6.92773 29.2693 7.31504C21.6778 9.5851 17.1811 15.6207 16.9223 15.9757C12.3394 22.2802 12.4364 28.8645 12.5227 30.6934C12.8138 36.8473 15.5097 41.1938 16.739 42.9474C16.836 43.0658 16.9762 43.2487 17.1487 43.4638C17.2781 43.636 17.4075 43.8081 17.5369 43.9803C20.7504 48.1976 25.0098 51.1347 29.7437 52.5979C33.2715 53.6742 36.9993 53.9313 40.642 53.3495C44.2847 52.7677 47.7458 51.3624 50.7605 49.2412C53.4132 47.3692 55.02 45.4219 56.012 44.2169C57.3276 42.6247 58.2873 41.0539 58.6 40.4299C58.6432 40.3546 59.0961 39.4294 59.1177 39.4294Z" fill="#ECB800"/>
                        <path d="M24.449 39.5263C18.7554 39.5263 15.9086 35.7715 15.9086 30.0157C15.9086 24.0662 18.9064 20.4836 24.7725 20.4836C27.037 20.4836 28.8594 21.0215 30.2613 22.1619C31.5768 23.3346 32.3101 24.9269 32.4934 26.9495H30.2073C29.3878 26.9495 28.7947 26.5729 28.4389 25.8306C27.8134 24.5073 26.5841 23.8295 24.7833 23.8295C21.2679 23.8295 19.7043 26.304 19.7043 30.0264C19.7043 33.6413 21.2032 36.1911 24.6647 36.1911C27.037 36.1911 28.3957 34.8893 28.8055 32.8452H32.4826C32.1483 37.1056 29.1398 39.5263 24.449 39.5263Z" fill="white"/>
                        <path d="M44.1072 20.5266H37.6479C36.5588 20.5266 35.6961 21.4733 35.6961 22.5599V39.4617H39.5782V32.716H44.3875C48.1941 32.716 50.2321 30.3491 50.2321 26.6159C50.2321 22.6783 48.1402 20.5266 44.1072 20.5266ZM43.6651 29.2948H39.8801V23.8079H43.8592C45.6708 23.8079 46.5981 24.7224 46.5981 26.5621C46.6089 28.4018 45.6384 29.3271 43.6651 29.2948Z" fill="#ECB800"/>
                        </svg><span style="margin-left: 0.7px;"> Merchant Points:</span>
                    </span>
                </div>
                <div class="col-7 text-right text-bold" style="font-size:15px;color:#179346;padding:0px!important;">
                    {{pointNumberFormatter(rewardPoints.available_merchant_points)}}
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                      <hr style="margin:0px!important;border:1px solid #545454;">
                </div>
            </div>
            <div class="row mt-3 text-right" style="font-size:12px;" v-if="sortedOtherPoints.length > 0">
                <div class="col-12" v-for="(other_point, index) in sortedOtherPoints" :key="index">
                      <span style="color:#ffffff;">{{other_point.merchant_name}}: </span><span style="color:#179346" class="text-bold">{{pointNumberFormatter(other_point.reward_point)}}</span>
                </div>
            </div>
        </div>
        
 

        <!-- Pending Reward Points Card -->
        <div class="rp-pending-card mb-3">
            <ul class="rp-pending-text">
                <li class="column justify-content-between m-0">
                    <div class="d-flex align-items-center">
                        <svg  fill="#fff" width="35px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 531.99956 473.76001">
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                            <g>
                                <path d="M310.801,169.12V145.042h10.641a16.238,16.238,0,0,0,0-32.476h-168a16.238,16.238,0,1,0,0,32.476h10.641V169.12a62.32421,62.32421,0,0,0,22.398,47.602l25.199,20.719L186.481,258.16a60.54205,60.54205,0,0,0-22.398,47.602V329.84H153.442a16.238,16.238,0,1,0,0,32.476h168a16.238,16.238,0,0,0,0-32.476l-10.641.0039v-24.078a62.32414,62.32414,0,0,0-22.398-47.602l-25.762-21.281,25.199-20.719c14.559-11.762,22.961-28.562,22.961-47.043Zm-32.48,135.52v24.078h-82.32V304.64a28.6915,28.6915,0,0,1,10.641-22.398l30.238-24.641,30.238,24.641A28.36211,28.36211,0,0,1,278.321,304.64ZM267.68,190.96l-30.238,24.641L207.204,190.96a28.705,28.705,0,0,1-10.641-22.398V144.484h81.762v24.078c-.00391,8.96091-3.92581,17.359-10.645,22.398Z"/>
                                <path d="M526.961,246.4a17.58121,17.58121,0,0,0-24.641,0l-28,28V236.88C474.32,106.4,367.92,0,237.44,0S0,106.4,0,236.88C0,367.92,106.4,473.76,236.88,473.76c52.078,0,101.36-16.801,142.8-47.602,7.83982-5.60159,8.96091-16.801,3.35941-24.078-5.60159-7.83982-16.801-8.96091-24.078-3.35941-35.281,26.879-77.281,40.879-122.08,40.879-111.44-.55859-202.16-91.277-202.16-202.72s90.719-202.16,202.16-202.16,202.72,90.719,202.72,202.16v37.52l-28-28a17.42381,17.42381,0,1,0-24.641,24.641l57.68,57.68a17.58007,17.58007,0,0,0,24.64,0l57.68-57.68a17.58121,17.58121,0,0,0,0-24.641Z"/>
                            </g>
                            </g>
                        </g>
                        </svg>
                        <span class="text">Pending Reward Points</span>
                    </div>
                    <div style="margin-left: 32px;" class="value d-flex align-items-center">
                        <svg class="ml-2" width="35" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="35" cy="35" r="35" fill="black"/>
                        <path d="M58.0327 44.4525C60.969 38.2154 59.7673 32.2515 59.4272 30.6694C59.0417 28.8825 58.0667 24.66 54.5183 20.8699C52.8282 19.0889 50.821 17.6413 48.6004 16.6018C45.6075 15.2019 43.0567 14.9287 41.6622 14.7921C35.9144 14.223 31.1643 15.9075 28.5114 17.114C29.8031 15.8716 31.2794 14.8387 32.8875 14.0523C34.3086 13.3704 35.8149 12.8842 37.3656 12.6069C39.9046 12.1197 42.5076 12.0697 45.0633 12.4589C46.2423 12.641 51.1626 13.4946 56.0714 17.3188C63.225 22.8844 66.1613 30.6125 64.4947 39.5016C61.9553 53.1026 48.9632 61.0697 35.7897 57.5641C31.9125 56.5284 27.8312 54.0814 26.0173 51.6912C26.108 51.6912 26.2101 51.6685 26.2667 51.7026C26.7202 51.9985 27.1624 52.3172 27.6272 52.6131C34.9962 57.2568 42.5465 57.291 50.0515 53.1367C51.0019 52.6094 51.9047 52.0001 52.7497 51.3156C56.0034 48.6751 57.4885 45.6021 58.0327 44.4525Z" fill="white"/>
                        <path d="M65.1182 45.2722C65.0389 45.7502 64.9028 46.4331 64.6874 47.2412C63.1229 53.023 59.5518 56.6765 58.0213 58.2016C52.4209 63.79 45.9022 65.4062 42.9206 66.1119C36.7873 67.5573 31.8331 66.9427 30.0532 66.6582C23.6479 65.6338 19.2492 63.0388 17.9001 62.1852C15.1477 60.4502 12.6808 58.2961 10.5878 55.8001C8.14327 52.8969 6.22821 49.5834 4.93068 46.012C4.60191 45.1014 3.35485 41.5276 3.03741 36.6335C2.74266 31.9671 3.44554 28.4843 3.6156 27.6762C4.17003 25.0362 5.06567 22.4803 6.27977 20.0733C6.91463 18.8441 10.1456 12.8346 17.2879 8.35027C18.9431 7.30316 23.2738 4.84474 29.407 3.91145C31.0282 3.66105 45.0066 1.72618 55.0171 10.5242C59.6539 14.5988 62.386 20.0505 62.8168 20.9269C63.4801 22.2806 64.0557 23.6759 64.54 25.104C64.3813 24.8877 63.2023 23.226 63.1683 23.1805C58.8149 16.7044 53.7587 13.6541 53.7587 13.6541C51.8468 12.5265 49.7911 11.665 47.6481 11.0932C40.8346 9.24941 35.0981 10.8884 33.7377 11.2981C25.7565 13.6996 21.0291 20.0847 20.757 20.4603C15.9388 27.1299 16.0408 34.0954 16.1315 36.0303C16.4376 42.5406 19.2718 47.1387 20.5642 48.9939C20.6663 49.1191 20.8137 49.3126 20.995 49.5403C21.1311 49.7224 21.2671 49.9045 21.4032 50.0866C24.7816 54.5482 29.2596 57.6553 34.2365 59.2032C37.9454 60.3419 41.8646 60.6138 45.6942 59.9984C49.5239 59.3829 53.1626 57.8962 56.3321 55.6522C59.121 53.6718 60.8102 51.6117 61.8532 50.337C63.2363 48.6525 64.2453 46.9908 64.5741 46.3306C64.6194 46.251 65.0956 45.2722 65.1182 45.2722Z" fill="#007EE5"/>
                        <path d="M28.7061 45.6C22.7202 45.6 19.7273 41.6278 19.7273 35.5387C19.7273 29.2447 22.879 25.4546 29.0462 25.4546C31.427 25.4546 33.3429 26.0237 34.8167 27.2301C36.1998 28.4707 36.9707 30.1552 37.1634 32.2949H34.76C33.8984 32.2949 33.2749 31.8966 32.9008 31.1112C32.2432 29.7113 30.9508 28.9943 29.0576 28.9943C25.3617 28.9943 23.7179 31.612 23.7179 35.5501C23.7179 39.3743 25.2937 42.0717 28.9329 42.0717C31.427 42.0717 32.8554 40.6945 33.2862 38.532H37.1521C36.8007 43.0392 33.6377 45.6 28.7061 45.6Z" fill="white"/>
                        <path d="M49.3373 25.2747H42.5465C41.4015 25.2747 40.4945 26.2762 40.4945 27.4258V45.3063H44.5758V38.17H49.632C53.634 38.17 55.7766 35.6661 55.7766 31.7166C55.7766 27.551 53.5773 25.2747 49.3373 25.2747ZM48.8725 34.5507H44.8932V28.746H49.0765C50.9811 28.746 51.9561 29.7135 51.9561 31.6597C51.9674 33.606 50.9471 34.5848 48.8725 34.5507H48.8725Z" fill="#007EE5"/>
                        </svg>
                        {{pointNumberFormatter(parseFloat(rewardPoints.pending_reward_point_generic) + parseFloat(rewardPoints.pending_reward_point_non_generic))}}
                    </div>
                </li>
            </ul>

            <div @click="showPendingInfoModal()" class="rp-imark-avatar">
                <svg width="18px" fill="#fff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 490 490" xml:space="preserve">
                <g id="bold_copy_37_">
                <path d="M245,0C109.684,0,0,109.684,0,245s109.684,245,245,245s245-109.684,245-245S380.316,0,245,0z M245,459.375
                c-118.213,0-214.375-96.163-214.375-214.375S126.787,30.625,245,30.625S459.375,126.787,459.375,245S363.212,459.375,245,459.375z"
                />
                <polygon points="266.836,286.987 275.196,114.874 214.788,114.874 223.532,286.987 	"/>
                <path d="M245.184,305.974c-20.136,0-34.178,14.424-34.178,34.576c0,19.738,13.674,34.576,34.178,34.576
                c20.503,0,33.825-14.823,33.825-34.576C278.611,320.399,265.304,305.974,245.184,305.974z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
            </div>
            <div style="font-size:11px;">
                <div class="text-left mt-1" style="padding:0px!important;color:#ffffff;margin-left:33px;margin-top:2px;">
                    <svg class="ml-3" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 45 45" fill="none">
                    <path d="M45 22.5C45 34.9264 34.9264 45 22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5Z" fill="black"/>
                    <path d="M37.3065 28.5767C39.194 24.5672 38.4215 20.7332 38.2029 19.7162C37.9551 18.5674 37.3283 15.8529 35.0472 13.4165C33.9607 12.2716 32.6704 11.3409 31.2428 10.6727C29.3188 9.77272 27.679 9.59712 26.7826 9.50932C23.0876 9.14348 20.0339 10.2264 18.3285 11.0019C19.1588 10.2033 20.1079 9.53928 21.1417 9.03373C22.0552 8.59538 23.0236 8.28281 24.0204 8.1045C25.6526 7.79137 27.326 7.7592 28.969 8.00939C29.7269 8.12646 32.8899 8.67521 36.0456 11.1336C40.6444 14.7115 42.532 19.6796 41.4606 25.394C39.8281 34.1375 31.4761 39.2592 23.0074 37.0056C20.5149 36.3398 17.8912 34.7667 16.7251 33.2302C16.7834 33.2302 16.849 33.2155 16.8855 33.2375C17.177 33.4277 17.4612 33.6326 17.76 33.8228C22.4972 36.8081 27.351 36.83 32.1757 34.1594C32.7866 33.8204 33.367 33.4287 33.9103 32.9887C36.0019 31.2912 36.9566 29.3157 37.3065 28.5767Z" fill="white"/>
                    <path d="M41.8619 29.1034C41.8109 29.4107 41.7234 29.8497 41.5849 30.3692C40.5792 34.0861 38.2835 36.4348 37.2996 37.4152C33.6993 41.0078 29.5087 42.0467 27.592 42.5004C23.6492 43.4296 20.4643 43.0345 19.3201 42.8516C15.2024 42.1931 12.3746 40.5249 11.5074 39.9761C9.738 38.8608 8.15213 37.476 6.80661 35.8714C5.23513 34.005 4.00402 31.8749 3.16989 29.579C2.95854 28.9937 2.15686 26.6962 1.9528 23.55C1.76331 20.5502 2.21517 18.3112 2.32449 17.7917C2.68091 16.0946 3.25668 14.4515 4.03717 12.9042C4.44529 12.114 6.52237 8.25072 11.1138 5.36793C12.1779 4.69479 14.9619 3.11438 18.9047 2.5144C19.9469 2.35344 28.933 1.10959 35.3683 6.76543C38.3491 9.38482 40.1055 12.8895 40.3824 13.4529C40.8088 14.3232 41.1788 15.2201 41.4902 16.1382C41.3882 15.9992 40.6302 14.9309 40.6083 14.9016C37.8098 10.7384 34.5593 8.77753 34.5593 8.77753C33.3302 8.05264 32.0087 7.49882 30.6311 7.13126C26.251 5.94595 22.5633 6.99956 21.6887 7.26297C16.558 8.8068 13.5189 12.9115 13.3439 13.1529C10.2465 17.4405 10.3121 21.9184 10.3704 23.1622C10.5672 27.3474 12.3892 30.3034 13.22 31.496C13.2856 31.5765 13.3804 31.7009 13.497 31.8472C13.5844 31.9643 13.6719 32.0813 13.7594 32.1984C15.9312 35.0666 18.8099 37.064 22.0094 38.0591C24.3937 38.7911 26.9131 38.9659 29.375 38.5703C31.837 38.1746 34.1762 37.2189 36.2137 35.7763C38.0065 34.5032 39.0924 33.1788 39.7629 32.3594C40.6521 31.2765 41.3007 30.2083 41.5121 29.7839C41.5412 29.7327 41.8473 29.1034 41.8619 29.1034Z" fill="#007EE5"/>
                    <path d="M18.4306 29.1693C14.5825 29.1693 12.6585 26.6158 12.6585 22.7013C12.6585 18.6551 14.6845 16.2187 18.6492 16.2187C20.1797 16.2187 21.4114 16.5845 22.3588 17.3601C23.2479 18.1576 23.7435 19.2405 23.8674 20.616H22.3224C21.7685 20.616 21.3676 20.3599 21.1271 19.8551C20.7044 18.9551 19.8736 18.4942 18.6565 18.4942C16.2806 18.4942 15.2238 20.177 15.2238 22.7086C15.2238 25.167 16.2369 26.9011 18.5763 26.9011C20.1797 26.9011 21.098 26.0158 21.3749 24.6256H23.8601C23.6342 27.523 21.6009 29.1693 18.4306 29.1693Z" fill="white"/>
                    <path d="M31.7171 16.248H27.3515C26.6155 16.248 26.0324 16.8919 26.0324 17.6309V29.1255H28.6561V24.5379H31.9065C34.4792 24.5379 35.8566 22.9282 35.8566 20.3893C35.8566 17.7114 34.4428 16.248 31.7171 16.248ZM31.4183 22.2112H28.8602V18.4796H31.5494C32.7738 18.4796 33.4006 19.1016 33.4006 20.3527C33.4079 21.6039 32.752 22.2331 31.4183 22.2112Z" fill="#007EE5"/>
                    </svg> <span class="ml-1">CanPay Points:</span> <span style="margin-left:22px;font-weight:700;font-family:'Montserrat'">{{pointNumberFormatter(rewardPoints.pending_reward_point_generic)}}</span>
                </div>
                <div class="text-left mt-1" style="padding:0px!important;color:#ffffff;margin-left:45px;margin-top:2px;">
                    <svg class="ml-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 45 45" fill="none">
                    <path d="M45 22.5C45 34.9264 34.9264 45 22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5Z" fill="black"/>
                    <path d="M37.3065 28.5767C39.194 24.5672 38.4215 20.7332 38.2029 19.7162C37.9551 18.5674 37.3283 15.8529 35.0472 13.4165C33.9607 12.2716 32.6704 11.3409 31.2428 10.6727C29.3188 9.77272 27.679 9.59712 26.7826 9.50932C23.0876 9.14348 20.0339 10.2264 18.3285 11.0019C19.1588 10.2033 20.1079 9.53928 21.1417 9.03373C22.0552 8.59538 23.0236 8.28281 24.0204 8.1045C25.6526 7.79137 27.326 7.7592 28.969 8.00939C29.7269 8.12646 32.8899 8.67521 36.0456 11.1336C40.6444 14.7115 42.532 19.6796 41.4606 25.394C39.8281 34.1375 31.4761 39.2592 23.0074 37.0056C20.5149 36.3398 17.8912 34.7667 16.7251 33.2302C16.7834 33.2302 16.849 33.2155 16.8855 33.2375C17.177 33.4277 17.4612 33.6326 17.76 33.8228C22.4972 36.8081 27.351 36.83 32.1757 34.1594C32.7866 33.8204 33.367 33.4287 33.9103 32.9887C36.0019 31.2912 36.9566 29.3157 37.3065 28.5767Z" fill="white"/>
                    <path d="M41.8619 29.1034C41.8109 29.4107 41.7234 29.8497 41.5849 30.3692C40.5792 34.0861 38.2835 36.4348 37.2996 37.4152C33.6993 41.0078 29.5087 42.0467 27.592 42.5004C23.6492 43.4296 20.4643 43.0345 19.3201 42.8516C15.2024 42.1931 12.3746 40.5249 11.5074 39.9761C9.738 38.8608 8.15213 37.476 6.80661 35.8714C5.23513 34.005 4.00402 31.8749 3.16989 29.579C2.95854 28.9937 2.15686 26.6962 1.9528 23.55C1.76331 20.5502 2.21517 18.3112 2.32449 17.7917C2.68091 16.0946 3.25668 14.4515 4.03717 12.9042C4.44529 12.114 6.52237 8.25072 11.1138 5.36793C12.1779 4.69479 14.9619 3.11438 18.9047 2.5144C19.9469 2.35344 28.933 1.10959 35.3683 6.76543C38.3491 9.38482 40.1055 12.8895 40.3824 13.4529C40.8088 14.3232 41.1788 15.2201 41.4902 16.1382C41.3882 15.9992 40.6302 14.9309 40.6083 14.9016C37.8098 10.7384 34.5593 8.77753 34.5593 8.77753C33.3302 8.05264 32.0087 7.49882 30.6311 7.13126C26.251 5.94595 22.5633 6.99956 21.6887 7.26297C16.558 8.8068 13.5189 12.9115 13.3439 13.1529C10.2465 17.4405 10.3121 21.9184 10.3704 23.1622C10.5672 27.3474 12.3892 30.3034 13.22 31.496C13.2856 31.5765 13.3804 31.7009 13.497 31.8472C13.5844 31.9643 13.6719 32.0813 13.7594 32.1984C15.9312 35.0666 18.8099 37.064 22.0094 38.0591C24.3937 38.7911 26.9131 38.9659 29.375 38.5703C31.837 38.1746 34.1762 37.2189 36.2137 35.7763C38.0065 34.5032 39.0924 33.1788 39.7629 32.3594C40.6521 31.2765 41.3007 30.2083 41.5121 29.7839C41.5412 29.7327 41.8473 29.1034 41.8619 29.1034Z" fill="#FFCB11"/>
                    <path d="M18.4306 29.1693C14.5825 29.1693 12.6585 26.6158 12.6585 22.7013C12.6585 18.6551 14.6845 16.2187 18.6492 16.2187C20.1797 16.2187 21.4114 16.5845 22.3588 17.3601C23.2479 18.1576 23.7435 19.2405 23.8674 20.616H22.3224C21.7685 20.616 21.3676 20.3599 21.1271 19.8551C20.7044 18.9551 19.8736 18.4942 18.6565 18.4942C16.2806 18.4942 15.2238 20.177 15.2238 22.7086C15.2238 25.167 16.2369 26.9011 18.5763 26.9011C20.1797 26.9011 21.098 26.0158 21.3749 24.6256H23.8601C23.6342 27.523 21.6009 29.1693 18.4306 29.1693Z" fill="white"/>
                    <path d="M31.7171 16.248H27.3515C26.6155 16.248 26.0324 16.8919 26.0324 17.6309V29.1255H28.6561V24.5379H31.9065C34.4792 24.5379 35.8566 22.9282 35.8566 20.3893C35.8566 17.7114 34.4428 16.248 31.7171 16.248ZM31.4183 22.2112H28.8602V18.4796H31.5494C32.7738 18.4796 33.4006 19.1016 33.4006 20.3527C33.4079 21.6039 32.752 22.2331 31.4183 22.2112Z" fill="#FFCB11"/>
                    </svg> <span class="ml-1">Merchant Points:</span> <span style="font-weight:700;margin-left:10px;font-family:'Montserrat'">{{pointNumberFormatter(rewardPoints.pending_reward_point_non_generic)}}</span>
                </div>
            </div>
        </div>

        <!-- ////////////////////DO NOT REMOVE THIS CODE ////////////////////////// -->
        <!-- Invite Card -->
        <!-- <div class="rp-invite-card mb-3">
            <div class="rp-invite-card-header">
                <svg class="mr-2" width="40" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M58.0327 44.4525C60.969 38.2154 59.7673 32.2515 59.4272 30.6694C59.0417 28.8825 58.0667 24.66 54.5183 20.8699C52.8282 19.0889 50.821 17.6413 48.6004 16.6018C45.6075 15.2019 43.0567 14.9287 41.6622 14.7921C35.9144 14.223 31.1643 15.9075 28.5114 17.114C29.8031 15.8716 31.2794 14.8387 32.8875 14.0523C34.3086 13.3704 35.8149 12.8842 37.3656 12.6069C39.9046 12.1197 42.5076 12.0697 45.0633 12.4589C46.2423 12.641 51.1626 13.4946 56.0714 17.3188C63.225 22.8844 66.1613 30.6125 64.4947 39.5016C61.9553 53.1026 48.9632 61.0697 35.7897 57.5641C31.9125 56.5284 27.8312 54.0814 26.0173 51.6912C26.108 51.6912 26.2101 51.6685 26.2667 51.7026C26.7202 51.9985 27.1624 52.3172 27.6272 52.6131C34.9962 57.2568 42.5465 57.291 50.0515 53.1367C51.0019 52.6094 51.9047 52.0001 52.7497 51.3156C56.0034 48.6751 57.4885 45.6021 58.0327 44.4525Z" fill="black"/>
                <path d="M65.1182 45.2722C65.0389 45.7502 64.9028 46.4331 64.6874 47.2412C63.1229 53.023 59.5518 56.6765 58.0213 58.2016C52.4209 63.79 45.9022 65.4062 42.9206 66.1119C36.7873 67.5573 31.8331 66.9427 30.0532 66.6582C23.6479 65.6338 19.2492 63.0388 17.9001 62.1852C15.1477 60.4502 12.6808 58.2961 10.5878 55.8001C8.14327 52.8969 6.22821 49.5834 4.93068 46.012C4.60191 45.1014 3.35485 41.5276 3.03741 36.6335C2.74266 31.9671 3.44554 28.4843 3.6156 27.6762C4.17003 25.0362 5.06567 22.4803 6.27977 20.0733C6.91463 18.8441 10.1456 12.8346 17.2879 8.35027C18.9431 7.30316 23.2738 4.84474 29.407 3.91145C31.0282 3.66105 45.0066 1.72618 55.0171 10.5242C59.6539 14.5988 62.386 20.0505 62.8168 20.9269C63.4801 22.2806 64.0557 23.6759 64.54 25.104C64.3813 24.8877 63.2023 23.226 63.1683 23.1805C58.8149 16.7044 53.7587 13.6541 53.7587 13.6541C51.8468 12.5265 49.7911 11.665 47.6481 11.0932C40.8346 9.24941 35.0981 10.8884 33.7377 11.2981C25.7565 13.6996 21.0291 20.0847 20.757 20.4603C15.9388 27.1299 16.0408 34.0954 16.1315 36.0303C16.4376 42.5406 19.2718 47.1387 20.5642 48.9939C20.6663 49.1191 20.8137 49.3126 20.995 49.5403C21.1311 49.7224 21.2671 49.9045 21.4032 50.0866C24.7816 54.5482 29.2596 57.6553 34.2365 59.2032C37.9454 60.3419 41.8646 60.6138 45.6942 59.9984C49.5239 59.3829 53.1626 57.8962 56.3321 55.6522C59.121 53.6718 60.8102 51.6117 61.8532 50.337C63.2363 48.6525 64.2453 46.9908 64.5741 46.3306C64.6194 46.251 65.0956 45.2722 65.1182 45.2722Z" fill="#007EE5"/>
                <path d="M28.7061 45.6C22.7202 45.6 19.7273 41.6278 19.7273 35.5387C19.7273 29.2447 22.879 25.4546 29.0462 25.4546C31.427 25.4546 33.3429 26.0237 34.8167 27.2301C36.1998 28.4707 36.9707 30.1552 37.1634 32.2949H34.76C33.8984 32.2949 33.2749 31.8966 32.9008 31.1112C32.2432 29.7113 30.9508 28.9943 29.0576 28.9943C25.3617 28.9943 23.7179 31.612 23.7179 35.5501C23.7179 39.3743 25.2937 42.0717 28.9329 42.0717C31.427 42.0717 32.8554 40.6945 33.2862 38.532H37.1521C36.8007 43.0392 33.6377 45.6 28.7061 45.6Z" fill="black"/>
                <path d="M49.3373 25.2747H42.5465C41.4015 25.2747 40.4945 26.2762 40.4945 27.4258V45.3063H44.5758V38.17H49.632C53.634 38.17 55.7766 35.6661 55.7766 31.7166C55.7766 27.551 53.5773 25.2747 49.3373 25.2747ZM48.8725 34.5507H44.8932V28.746H49.0765C50.9811 28.746 51.9561 29.7135 51.9561 31.6597C51.9674 33.606 50.9471 34.5848 48.8725 34.5507H48.8725Z" fill="#007EE5"/>
                </svg>
                <span>Give & Get Points</span>
            </div>
            <div class="rp-invite-card-body">
                <div class="content mb-3">
                    When a Friend uses your link to sign up <br> for CanPay, you each get 3,300 points.
                </div>
                <a @click="shareOnSocialMedia()" class="link" href="javascript:void(0)">
                    Share Invite Link
                </a>
            </div>
        </div> -->

        <!------------------------------------------------------------------------>
        <!-----------------------  MODAL FOR PENDING REWARD INFO  !------------------------>
        <!--------------------------------------------------------------------------->
        <b-modal
        ref="pending-reward-info-modal"
        hide-footer
        v-b-modal.modal-center
        no-close-on-backdrop
        modal-backdrop
        hide-header
        id="pending-reward-info-modal"
        centered
        title="BootstrapVue"
        >
        <h5 class="text-center mb-3">Pending Rewards Points</h5>
        <p class="justify-text mb-3">All points won on spins from transaction-related wheels or awarded as part of the Merchant Points program will be pending until the associated transaction is considered fully cleared in the App. This typically takes a total of 3-4 banking days, even though you will see the debit to your bank account for the purchase after 2 banking days. Once the associated payment clears in the App, the pending points will transfer to your Available Rewards Points balance. Thanks for making CanPay Even More Rewarding.</p>
        <div class="row justify-content-center">
            <div class="col-6">
            <button class="btn other-points-btn btn-primary w-100 mt-0" @click="hidePendingInfoModal('pending-reward-info-modal')">OK</button>
            </div>
        </div>
        </b-modal>
        
        <div class="text-left text-white mb-3" style="font-size: 17px;">History</div>
        <div v-if="maxRewardPointHistories.length > 0">
        <a v-for="history in maxRewardPointHistories" :key="history.id" class="rp-detail-card-link" data-toggle="collapse" href="#rpDetailCollapse1" role="button" aria-expanded="false" aria-controls="rpDetailCollapse1">
        <div class="rp-detail-card mb-3 pb-1" style="position: relative;">
            <div class="rp-detail-card-header">
                <div v-if="history.sponsor_link_id || history.is_brand" class="tran-title mb-2">+${{ pointNumberFormatter(amountNumberFormatter(history.reward_amount)) }}</div>
                
                <div v-if="history.sponsor_link_id" class="tran-store-name mb-0">CannaPlan Funds to Points</div>
                <div v-if="history.is_brand && history.reason == 'Brand converted to points'" class="tran-store-name mb-0">{{history.retailer}} Funds to Points</div>
                <hr v-if="history.sponsor_link_id || history.is_brand" class="my-2">
                <div v-if="!history.sponsor_link_id && !history.is_brand && history.entry_type == 'Cr'" >
                    <div>
                        <div class="tran-title mb-2">+${{ pointNumberFormatter(amountNumberFormatter(history.reward_amount)) }}</div>
                        <div class="tran-store-name mb-0">{{history.retailer}}</div>
                    </div>
                    <div class="tran-store-logo">
                        <img v-bind:src="history.logo_url" v-if="history.logo_url != null && history.logo_url != ''" alt="" style="width:100%; height: 90%;">
                    </div>
                    <hr class="my-2">
                </div>
                <div class="row justify-content-between m-0">
                    <div class="rp-title">
                        <svg class="mr-2" width="30" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="(history.segment_name!= 'Loser' && history.is_generic_point == 1 && history.reason != 'transaction') || (history.reason == 'transaction' && isRewardUsed(history.generic_reward_amount_used))">
                        <path d="M58.0327 44.4525C60.969 38.2154 59.7673 32.2515 59.4272 30.6694C59.0417 28.8825 58.0667 24.66 54.5183 20.8699C52.8282 19.0889 50.821 17.6413 48.6004 16.6018C45.6075 15.2019 43.0567 14.9287 41.6622 14.7921C35.9144 14.223 31.1643 15.9075 28.5114 17.114C29.8031 15.8716 31.2794 14.8387 32.8875 14.0523C34.3086 13.3704 35.8149 12.8842 37.3656 12.6069C39.9046 12.1197 42.5076 12.0697 45.0633 12.4589C46.2423 12.641 51.1626 13.4946 56.0714 17.3188C63.225 22.8844 66.1613 30.6125 64.4947 39.5016C61.9553 53.1026 48.9632 61.0697 35.7897 57.5641C31.9125 56.5284 27.8312 54.0814 26.0173 51.6912C26.108 51.6912 26.2101 51.6685 26.2667 51.7026C26.7202 51.9985 27.1624 52.3172 27.6272 52.6131C34.9962 57.2568 42.5465 57.291 50.0515 53.1367C51.0019 52.6094 51.9047 52.0001 52.7497 51.3156C56.0034 48.6751 57.4885 45.6021 58.0327 44.4525Z" fill="black"/>
                        <path d="M65.1182 45.2722C65.0389 45.7502 64.9028 46.4331 64.6874 47.2412C63.1229 53.023 59.5518 56.6765 58.0213 58.2016C52.4209 63.79 45.9022 65.4062 42.9206 66.1119C36.7873 67.5573 31.8331 66.9427 30.0532 66.6582C23.6479 65.6338 19.2492 63.0388 17.9001 62.1852C15.1477 60.4502 12.6808 58.2961 10.5878 55.8001C8.14327 52.8969 6.22821 49.5834 4.93068 46.012C4.60191 45.1014 3.35485 41.5276 3.03741 36.6335C2.74266 31.9671 3.44554 28.4843 3.6156 27.6762C4.17003 25.0362 5.06567 22.4803 6.27977 20.0733C6.91463 18.8441 10.1456 12.8346 17.2879 8.35027C18.9431 7.30316 23.2738 4.84474 29.407 3.91145C31.0282 3.66105 45.0066 1.72618 55.0171 10.5242C59.6539 14.5988 62.386 20.0505 62.8168 20.9269C63.4801 22.2806 64.0557 23.6759 64.54 25.104C64.3813 24.8877 63.2023 23.226 63.1683 23.1805C58.8149 16.7044 53.7587 13.6541 53.7587 13.6541C51.8468 12.5265 49.7911 11.665 47.6481 11.0932C40.8346 9.24941 35.0981 10.8884 33.7377 11.2981C25.7565 13.6996 21.0291 20.0847 20.757 20.4603C15.9388 27.1299 16.0408 34.0954 16.1315 36.0303C16.4376 42.5406 19.2718 47.1387 20.5642 48.9939C20.6663 49.1191 20.8137 49.3126 20.995 49.5403C21.1311 49.7224 21.2671 49.9045 21.4032 50.0866C24.7816 54.5482 29.2596 57.6553 34.2365 59.2032C37.9454 60.3419 41.8646 60.6138 45.6942 59.9984C49.5239 59.3829 53.1626 57.8962 56.3321 55.6522C59.121 53.6718 60.8102 51.6117 61.8532 50.337C63.2363 48.6525 64.2453 46.9908 64.5741 46.3306C64.6194 46.251 65.0956 45.2722 65.1182 45.2722Z" fill="#007EE5"/>
                        <path d="M28.7061 45.6C22.7202 45.6 19.7273 41.6278 19.7273 35.5387C19.7273 29.2447 22.879 25.4546 29.0462 25.4546C31.427 25.4546 33.3429 26.0237 34.8167 27.2301C36.1998 28.4707 36.9707 30.1552 37.1634 32.2949H34.76C33.8984 32.2949 33.2749 31.8966 32.9008 31.1112C32.2432 29.7113 30.9508 28.9943 29.0576 28.9943C25.3617 28.9943 23.7179 31.612 23.7179 35.5501C23.7179 39.3743 25.2937 42.0717 28.9329 42.0717C31.427 42.0717 32.8554 40.6945 33.2862 38.532H37.1521C36.8007 43.0392 33.6377 45.6 28.7061 45.6Z" fill="black"/>
                        <path d="M49.3373 25.2747H42.5465C41.4015 25.2747 40.4945 26.2762 40.4945 27.4258V45.3063H44.5758V38.17H49.632C53.634 38.17 55.7766 35.6661 55.7766 31.7166C55.7766 27.551 53.5773 25.2747 49.3373 25.2747ZM48.8725 34.5507H44.8932V28.746H49.0765C50.9811 28.746 51.9561 29.7135 51.9561 31.6597C51.9674 33.606 50.9471 34.5848 48.8725 34.5507H48.8725Z" fill="#007EE5"/>
                        </svg>
                        
                        <span v-if="history.entry_type == 'Dr' && history.reason == 'transaction' && isRewardUsed(history.generic_reward_amount_used)">${{amountDecimalNumberFormatter(amountNumberFormatter(history.generic_reward_amount_used))}}</span>
                        <span v-if="history.entry_type == 'Dr' && history.reason == 'transaction' && isRewardUsed(history.generic_reward_amount_used) && isRewardUsed(history.merchant_reward_amount_used)" class="ml-2 mr-2"> + </span>
                        
                        <svg class="mr-2" width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="(history.segment_name!= 'Loser' && history.is_generic_point == 0) || (history.reason == 'transaction' && isRewardUsed(history.merchant_reward_amount_used))">
                        <path d="M43.6484 32.2123C45.9758 27.2992 45.0233 22.6013 44.7537 21.3551C44.4482 19.9476 43.6754 16.6214 40.8627 13.6359C39.5231 12.233 37.9321 11.0927 36.1719 10.2738C33.7996 9.17106 31.7777 8.95589 30.6724 8.8483C26.1164 8.40003 22.3512 9.72692 20.2485 10.6773C21.2723 9.69865 22.4425 8.88501 23.7171 8.26555C24.8436 7.72841 26.0375 7.34542 27.2667 7.12693C29.2792 6.74323 31.3425 6.70382 33.3683 7.01038C34.3028 7.15383 38.2028 7.82624 42.0938 10.8386C47.7641 15.2228 50.0915 21.3103 48.7705 28.3123C46.7576 39.0261 36.4595 45.3019 26.0176 42.5406C22.9443 41.7247 19.7093 39.7971 18.2715 37.9144C18.3434 37.9144 18.4243 37.8964 18.4692 37.9233C18.8287 38.1564 19.1791 38.4075 19.5476 38.6406C25.3886 42.2985 31.3733 42.3254 37.3222 39.053C38.0754 38.6376 38.791 38.1577 39.4609 37.6185C42.0399 35.5385 43.2171 33.1178 43.6484 32.2123Z" fill="black"/>
                        <path d="M49.2647 32.8578C49.2018 33.2344 49.094 33.7723 48.9232 34.4088C47.6831 38.9633 44.8525 41.8412 43.6394 43.0426C39.2002 47.4446 34.0332 48.7177 31.6698 49.2736C26.8083 50.4122 22.8814 49.9281 21.4706 49.7039C16.3934 48.897 12.9067 46.8529 11.8374 46.1805C9.65575 44.8138 7.70037 43.117 6.04133 41.1509C4.1037 38.8639 2.58573 36.2538 1.55724 33.4406C1.29664 32.7233 0.308168 29.9082 0.0565558 26.053C-0.177084 22.3772 0.380057 19.6337 0.514849 18.9972C0.954323 16.9176 1.66425 14.9043 2.62659 13.0083C3.12982 12.04 5.69087 7.30621 11.3521 3.77381C12.6641 2.94899 16.0968 1.01244 20.9583 0.277275C22.2434 0.0800349 33.3233 -1.4441 41.2581 5.48622C44.9334 8.69586 47.099 12.9903 47.4405 13.6807C47.9662 14.747 48.4225 15.8461 48.8064 16.971C48.6806 16.8006 47.746 15.4917 47.7191 15.4558C44.2684 10.3545 40.2606 7.95172 40.2606 7.95172C38.7451 7.06349 37.1157 6.38487 35.4171 5.93449C30.0164 4.48208 25.4694 5.77311 24.3911 6.09587C18.0648 7.98758 14.3176 13.0172 14.1019 13.3131C10.2828 18.5668 10.3637 24.0537 10.4356 25.5778C10.6782 30.7061 12.9247 34.3281 13.9491 35.7895C14.03 35.8881 14.1468 36.0406 14.2906 36.2199C14.3985 36.3633 14.5063 36.5068 14.6141 36.6502C17.292 40.1647 20.8415 42.6123 24.7864 43.8316C27.7263 44.7285 30.8328 44.9427 33.8684 44.4579C36.9039 43.9731 39.7882 42.802 42.3004 41.0343C44.511 39.4743 45.85 37.8516 46.6767 36.8474C47.773 35.5206 48.5728 34.2116 48.8334 33.6916C48.8693 33.6288 49.2467 32.8578 49.2647 32.8578Z" fill="#ECB800"/>
                        <path d="M20.3742 32.9385C15.6295 32.9385 13.2571 29.8096 13.2571 25.0131C13.2571 20.0552 15.7553 17.0697 20.6438 17.0697C22.5309 17.0697 24.0495 17.5179 25.2177 18.4683C26.314 19.4455 26.9251 20.7724 27.0778 22.4579H25.1728C24.4898 22.4579 23.9956 22.1441 23.6991 21.5255C23.1779 20.4227 22.1534 19.8579 20.6527 19.8579C17.7233 19.8579 16.4203 21.92 16.4203 25.022C16.4203 28.0344 17.6693 30.1592 20.5539 30.1592C22.5309 30.1592 23.6631 29.0744 24.0046 27.371H27.0689C26.7903 30.9213 24.2832 32.9385 20.3742 32.9385Z" fill="black"/>
                        <path d="M36.756 17.1055H31.3733C30.4657 17.1055 29.7468 17.8944 29.7468 18.7999V32.8847H32.9818V27.2634H36.9896C40.1617 27.2634 41.8601 25.291 41.8601 22.1799C41.8601 18.8986 40.1168 17.1055 36.756 17.1055ZM36.3876 24.4123H33.2334V19.8399H36.5493C38.059 19.8399 38.8318 20.602 38.8318 22.1351C38.8408 23.6682 38.032 24.4392 36.3876 24.4123Z" fill="#ECB800"/>
                        </svg>
                        <span v-if="history.entry_type == 'Dr' && history.reason == 'transaction' && isRewardUsed(history.merchant_reward_amount_used)">${{amountDecimalNumberFormatter(amountNumberFormatter(history.merchant_reward_amount_used))}}</span>
                        <span v-if="history.entry_type == 'Cr'">
                            <span v-if="history.segment_name != null">
                                <span v-if="history.segment_name == 'Amount'">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                <span v-if="history.segment_name == 'Jackpot'">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                <span v-if="history.segment_name == 'Free Spin'">{{pointNumberFormatter(history.reward_point)}} spin</span>
                                <span v-if="history.segment_name == 'Reward Points'">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                <span v-if="history.segment_name == 'Multiplier'">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                <span v-if="history.segment_name == 'Loser'">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                            </span>
                            <span style="font-weight: 700; color: #007ee5;" v-else-if="history.sponsor_link_id || history.is_brand">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                            <span v-else>{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                            <span v-if="history.status.toLowerCase() == 'pending' && history.segment_name!= 'Loser'" class="font-weight-bold ml-2"><small style="font-weight: 700; color: #007ee5;">(Pending)</small></span>
                        </span>
                        <span v-if="history.segment_name == 'Loser'" class="rp-text" style="text-transform:none;">Sorry! This spin didn’t win. <br/>
                            But it did gain you entry into the Daily 2nd Chance Drawing. Good luck!</span>
                    </div>
                    <div>
                        <span v-if="history.segment_name">
                            <div v-if="history.entry_type == 'Cr'" class="rp-text" style="text-transform: capitalize;"><span class="text-dark">Win:</span> {{history.segment_name}}</div>
                            <!-- <div v-if="history.entry_type == 'Cr' && history.segment_name == 'Loser'" class="rp-text" style="text-transform: capitalize;">{{history.reason}}</div> -->
                            <div v-if="history.entry_type == 'Dr'" class="rp-text" style="text-transform: capitalize; color: #007EE5!important;">Paid with Points</div>
                        </span>
                        <div v-else-if="history.sponsor_link_id" class="rp-text" style="font-weight: 700; color: #007ee5;">Canpay Points</div>
                        <div v-else-if="history.is_brand && history.is_generic_point == 1" class="rp-text" style="font-weight: 700; color: #007ee5;">Brand Points</div>
                        <div v-else-if="history.is_brand && history.is_generic_point == 0" class="rp-text" style="font-weight: 700; color: #ffc107;">Brand Points</div>
                        <div v-else class="rp-text" style="text-transform: capitalize;">{{history.reason}}</div>
                    </div>
                </div>
                <div v-if="history.entry_type == 'Cr' || history.segment_name== 'Loser'" class="font-weight-bold mt-2">{{history.reward_wheel_name}}</div>
                <div v-if="history.entry_type == 'Dr'" class="font-weight-bold mt-2">{{history.retailer}}</div>
                <hr class="my-2 mb-1">
                <ul class="rp-timing">
                    <li class="d-flex align-items-center">
                    <svg
                    width="30"
                    class="mr-2"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 90 90"
                    style="
                        margin-left: -8px;
                        margin-right: -10px;
                        float: left;
                    "
                    xml:space="preserve"
                    fill="#7f7f7f"
                    >
                    <path
                        d="M63,25h-2v-2c0-1.1-0.9-2-2-2s-2,0.9-2,2v2H33v-2c0-1.1-0.9-2-2-2s-2,0.9-2,2v2h-2c-3.3,0-6,2.7-6,6v32c0,3.3,2.7,6,6,6h36
                    c3.3,0,6-2.7,6-6V31C69,27.7,66.3,25,63,25z M65,63c0,1.1-0.9,2-2,2H27c-1.1,0-2-0.9-2-2V31c0-1.1,0.9-2,2-2h2v2c0,1.1,0.9,2,2,2
                c1.1,0,2-0.9,2-2v-2h24v2c0,1.1,0.9,2,2,2s2-0.9,2-2v-2h2c1.1,0,2,0.9,2,2V63z"
                    />
                    <path
                        d="M59,39H31c-1.1,0-2,0.9-2,2s0.9,2,2,2h28c1.1,0,2-0.9,2-2S60.1,39,59,39z"
                    />
                    </svg> {{formatedTime(history.rewards_time)}} - {{ history.timezone_name }}</li>
                </ul>
            </div>
        </div>
        </a>
        </div>
        <div v-else class="text-white">
            <small>No history found!</small>
        </div>

        <a v-if="rewardPointHistories.length > 2" class="rp-history-link" @click="routePush('rewardHistory')" href="javascript:void(0)">See all</a>
    </div>
  </div>
  </div>
</template>
<script>
import moment from "moment";
import rewardwheelapi from "../../api/rewardwheel.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue";
export default {
    name: "RewardPoints",
    data() {
        return {
            rewardPoints: {},
            usedRewardPoints: {},
            rewardPointHistories: [],
            maxRewardPointHistories: [],
            isLoading: true
        };
    },
    created() {
        this.getRewardData();
    },
    components: {
        Loading,
        CanPayLoader
    },
    computed: {
        sortedOtherPoints() {
        return this.rewardPoints.available_other_points
            .slice()
            .sort((a, b) => parseFloat(b.reward_point) - parseFloat(a.reward_point))
            .slice(0, 3);
        }
    },
    methods: {
        isRewardUsed(point){
            if(point){
                if(point == '' || point == '0.00'){
                return false
                }
                return true
            }else{
                return false
            }
        },
        showPendingInfoModal(){
            this.$refs['pending-reward-info-modal'].show();
        },
        hidePendingInfoModal(){
            this.$refs['pending-reward-info-modal'].hide();
        },
        formatedTime(data){
            return moment(data).format('DD MMM Y | hh:mm A');
        },  
        numberFormat(val){
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        routePush(url){
            this.$router.push("/"+url).catch((err) => {});
        },
        async shareOnSocialMedia(){
            
            const title = 'CanPay - Reward Wheel';
            const url = 'https://test-canpay-admin.rivethammer.com';
            const text = "Signup on CanPay and get reward points.";

            var image = require('../../assets/images/logo.png')
            const response = await fetch(image)
            const blob = await response.blob()
            const filesArray = [new File([blob], image, {type: 'image/png'})];

            try {
                if (navigator.canShare({ files: filesArray })) {
                    navigator
                    .share({
                        title,
                        url,
                        text,
                        files: filesArray
                    })
                }else{
                    navigator
                    .share({
                        title,
                        url,
                        text
                    })
                }
            } catch (err) {
                alert(`Couldn't share ${err}`);
            }
        },
        getRewardData(){

            Promise.all([
                rewardwheelapi
                .userRewards()
                .then((response) => {
                    this.rewardPoints = response.data
                })
                .catch(function (error) {
                }),

                rewardwheelapi
                .usedRewardPoint()
                .then((response) => {
                    this.usedRewardPoints = response.data
                })
                .catch(function (error) {
                }),
                
                rewardwheelapi
                .rewardPointHistory({
                    page: 1,
                    rowsPerPage: 10
                })
                .then((response) => {
                    this.rewardPointHistories = response.data.data;    
                    if(response.data.data.length > 3){
                        this.maxRewardPointHistories = response.data.data.filter((data,key) => key < 3);    
                    }else{
                        this.maxRewardPointHistories = response.data.data;    
                    }
                })
                .catch(function (error) {
                })
            ])
            .then((values) => {
                this.isLoading = false
            })
            .catch((error) => {
                this.isLoading = false
            });

            
        },
        amountNumberFormatter(amount) {
            return parseFloat(amount).toFixed(2);
        }
    },
    mounted() {
        let self = this;

        var element = document.getElementsByClassName("content-wrap");
        if (element[0]) {
            element[0].style.setProperty("background", "linear-gradient(0deg, #148f3f, #00ae44)");
            element[0].style.height = "114vh";
      if(window.innerWidth>1200){
        element[0].style.height = "121vh";
      }
        }
        this.$root.$emit("loginapp", [""]);
        this.$root.$emit("changeWhiteBackground", [
            false,
            false,
            "RewardPointHeader"
        ]);
    },
};
</script>

<style>
#pending-reward-info-modal___BV_modal_body_{
    background-color: #fff!important;
    border-radius: 6px;
}
.other-points-btn.btn-primary{
  background-color: #118037;
  border-color: #118037;
}
.other-points-btn{
  padding: 10px!important;
  height: 45px;
  margin-top: 15px;
}
</style>
<style scoped>
.min-h{
    min-height: calc(100vh - 115px)!important;
}

.rp-available-card{
    background: linear-gradient(45deg, #000, black);
    padding: 25px;
    border-radius: 6px;
    position: relative;
}
.rp-available-title{
    text-align: left;
    color: #dddddd;
    font-size: 18px;
}
.rp-available-value{
    text-align: left;
    color: #1fb859;
    font-size: 45px;
}
.rp-icon-avatar{
    border-radius: 100%;
    background: #1e1e1e;
    position: absolute;
    top: 10px;
    right: 10px;
}

.rp-pending-card{
    padding: 12px 20px;
    border-radius: 6px;
    position: relative;
    background: linear-gradient(45deg, #0e8740, #006b2c);

}
.rp-pending-text{
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
}
.rp-pending-text .text{
    color: #fff;
    margin-left: 10px;
    font-weight: 600;
    font-size: 17px;
}
.rp-pending-text .value{
    color: #fff;
    font-size: 35px;
}
.rp-imark-avatar{
    position: absolute;
    top: 4px;
    right: 7px;
    cursor: pointer;
}
.rp-invite-card{
    background: #fff;
    border-radius: 6px;
}
.rp-invite-card-header{
    background: #e7e8e7;
    padding: 17px 20px;
    font-size: 22px;
    color: #00943f;
    text-align: left;
    font-weight: 700;
    border-radius: 6px 6px 0 0;
}
.rp-invite-card-body{
    background: #ffffff;
    padding: 20px;
    border-radius: 0 0 6px 6px;
    text-align: left;
}
.rp-invite-card-body .content{
    background: #ffffff;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    font-weight: 600;
}
.rp-invite-card-body .link{
    background: #ffffff;
    color: #00943f;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    font-weight: bold;
}

.rp-detail-card-link, .rp-detail-card-link:hover{
    text-decoration: none;
    color: unset;
}
.rp-detail-card{
    background: white;
    border-radius: 7px;
    text-align: left;
}

.rp-detail-card{
    padding: 10px 20px;
}
.rp-title{
    font-weight: 800;
    font-size: 19px;
}
.rp-text{
    font-weight: 500;
    font-size: 15px;
    color: #00943f;
    font-weight: 700;
    text-transform: capitalize;
}
.rp-timing{
    list-style: none;
    margin: 0;
    padding: 0;
    color: #919090;
    font-size: 13px;
    font-weight: 600;
}

.rp-history-link{
    color: #fff;
    text-decoration: underline;
}
.tran-store-logo{
    position: absolute;
    width: 80px;
    top: 10px;
    right: 10px;
}

.tran-store-name{
    font-weight: 600;
    font-size: 16px;
}

.tran-title {
    font-weight: 600;
    font-size: 25px;
}

@media only screen and ( min-width:280px) and ( max-width:700px) {
    .rp-available-card{
    background: linear-gradient(45deg, #000, black);
    padding: 25px;
    border-radius: 6px;
    position: relative;
    }
    .rp-available-title{
        text-align: left;
        color: #dddddd;
        font-size: 13px;
    }
    .rp-icon-avatar svg{
        width: 35px;
    }
    .rp-available-value{
        text-align: left;
        color: #1fb859;
        font-size: 35px;
    }
    .rp-icon-avatar{
        border-radius: 100%;
        background: #1e1e1e;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .rp-pending-card{
        padding: 12px 20px;
        border-radius: 6px;
        position: relative;
        background: linear-gradient(45deg, #0e8740, #006b2c);

    }
    .rp-pending-text{
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: left;
    }
    .rp-pending-text .text{
        color: #fff;
        margin-left: 10px;
        font-weight: 600;
        font-size: 13px;
    }
    .rp-pending-text .value{
        color: #fff;
        font-size: 35px;
    }
    .rp-imark-avatar{
        position: absolute;
        top: 4px;
        right: 7px;
    }
    .rp-invite-card{
        background: #fff;
        border-radius: 6px;
    }
    .rp-invite-card-header{
        background: #e7e8e7;
        padding: 17px 20px;
        font-size: 17px;
        color: #00943f;
        text-align: left;
        font-weight: 700;
        border-radius: 6px 6px 0 0;
    }
    .rp-invite-card-body{
        background: #ffffff;
        padding: 20px;
        border-radius: 0 0 6px 6px;
        text-align: left;
    }
    .rp-invite-card-body .content{
        background: #ffffff;
        text-align: left;
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .rp-invite-card-body .link{
        background: #ffffff;
        color: #00943f;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        text-decoration: underline;
        font-weight: bold;
    }

    .rp-detail-card-link, .rp-detail-card-link:hover{
        text-decoration: none;
        color: unset;
    }
    .rp-detail-card{
        background: white;
        border-radius: 7px;
        text-align: left;
    }

    .rp-detail-card{
        padding: 10px 20px;
    }
    .rp-title{
        font-weight: 800;
        font-size: 19px;
    }
    .rp-text{
        font-weight: 500;
        font-size: 15px;
        color: #00943f;
        font-weight: 700;
        text-transform: capitalize;
    }
    .rp-timing{
        list-style: none;
        margin: 0;
        padding: 0;
        color: #919090;
        font-size: 13px;
        font-weight: 600;
    }

    .rp-history-link{
        color: #fff;
        text-decoration: underline;
    }
}

@media only screen and ( min-width:320px) and ( max-width:700px) {
    .rp-available-card{
    background: linear-gradient(45deg, #000, black);
    padding: 25px;
    border-radius: 6px;
    position: relative;
    }
    .rp-available-title{
        text-align: left;
        color: #dddddd;
        font-size: 15px;
    }
    .rp-icon-avatar svg{
        width: 40px;
    }
    .rp-available-value{
        text-align: left;
        color: #1fb859;
        font-size: 40px;
    }
    .rp-icon-avatar{
        border-radius: 100%;
        background: #1e1e1e;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .rp-pending-card{
        padding: 12px 20px;
        border-radius: 6px;
        position: relative;
        background: linear-gradient(45deg, #0e8740, #006b2c);

    }
    .rp-pending-text{
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: left;
    }
    .rp-pending-text .text{
        color: #fff;
        margin-left: 10px;
        font-weight: 600;
        font-size: 15px;
    }
    .rp-pending-text .value{
        color: #fff;
        font-size: 35px;
    }
    .rp-imark-avatar{
        position: absolute;
        top: 4px;
        right: 7px;
    }
    .rp-invite-card{
        background: #fff;
        border-radius: 6px;
    }
    .rp-invite-card-header{
        background: #e7e8e7;
        padding: 17px 20px;
        font-size: 19px;
        color: #00943f;
        text-align: left;
        font-weight: 700;
        border-radius: 6px 6px 0 0;
    }
    .rp-invite-card-body{
        background: #ffffff;
        padding: 20px;
        border-radius: 0 0 6px 6px;
        text-align: left;
    }
    .rp-invite-card-body .content{
        background: #ffffff;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .rp-invite-card-body .link{
        background: #ffffff;
        color: #00943f;
        text-align: left;
        font-size: 13px;
        font-weight: 500;
        text-decoration: underline;
        font-weight: bold;
    }

    .rp-detail-card-link, .rp-detail-card-link:hover{
        text-decoration: none;
        color: unset;
    }
    .rp-detail-card{
        background: white;
        border-radius: 7px;
        text-align: left;
    }

    .rp-detail-card{
        padding: 10px 20px;
    }
    .rp-title{
        font-weight: 800;
        font-size: 19px;
    }
    .rp-text{
        font-weight: 500;
        font-size: 15px;
        color: #00943f;
        font-weight: 700;
        text-transform: capitalize;
    }
    .rp-timing{
        list-style: none;
        margin: 0;
        padding: 0;
        color: #919090;
        font-size: 13px;
        font-weight: 600;
    }

    .rp-history-link{
        color: #fff;
        text-decoration: underline;
    }
}

@media only screen and ( min-width:376px) and ( max-width:800px) {
    .rp-available-card{
    background: linear-gradient(45deg, #000, black);
    padding: 25px;
    border-radius: 6px;
    position: relative;
    }
    .rp-available-title{
        text-align: left;
        color: #dddddd;
        font-size: 18px;
    }
    .rp-icon-avatar svg{
        width: 55px;
    }
    .rp-available-value{
        text-align: left;
        color: #1fb859;
        font-size: 45px;
    }
    .rp-icon-avatar{
        border-radius: 100%;
        background: #1e1e1e;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .rp-pending-card{
        padding: 12px 20px;
        border-radius: 6px;
        position: relative;
        background: linear-gradient(45deg, #0e8740, #006b2c);

    }
    .rp-pending-text{
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: left;
    }
    .rp-pending-text .text{
        color: #fff;
        margin-left: 10px;
        font-weight: 600;
        font-size: 17px;
    }
    .rp-pending-text .value{
        color: #fff;
        font-size: 35px;
    }
    .rp-imark-avatar{
        position: absolute;
        top: 4px;
        right: 7px;
    }
    .rp-invite-card{
        background: #fff;
        border-radius: 6px;
    }
    .rp-invite-card-header{
        background: #e7e8e7;
        padding: 17px 20px;
        font-size: 22px;
        color: #00943f;
        text-align: left;
        font-weight: 700;
        border-radius: 6px 6px 0 0;
    }
    .rp-invite-card-body{
        background: #ffffff;
        padding: 20px;
        border-radius: 0 0 6px 6px;
        text-align: left;
    }
    .rp-invite-card-body .content{
        background: #ffffff;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .rp-invite-card-body .link{
        background: #ffffff;
        color: #00943f;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        font-weight: bold;
    }

    .rp-detail-card-link, .rp-detail-card-link:hover{
        text-decoration: none;
        color: unset;
    }
    .rp-detail-card{
        background: white;
        border-radius: 7px;
        text-align: left;
    }

    .rp-detail-card{
        padding: 10px 20px;
    }
    .rp-title{
        font-weight: 800;
        font-size: 19px;
    }
    .rp-text{
        font-weight: 500;
        font-size: 15px;
        color: #00943f;
        font-weight: 700;
        text-transform: capitalize;
    }
    .rp-timing{
        list-style: none;
        margin: 0;
        padding: 0;
        color: #919090;
        font-size: 13px;
        font-weight: 600;
    }

    .rp-history-link{
        color: #fff;
        text-decoration: underline;
    }

}
</style>